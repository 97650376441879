import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import merge from '../../common/utils/merge';
import CancelButton from '../../components/CancelButton';
import { playSound } from '../../common/lib/sounds';
import { STRINGS, SK_MODEL, SK_MODELS, NORMALIZED_BUTTONS } from '../../common/normalized-smartknox-models';

const PAGE_SIZE = 20; // ensure this number is even or things might get weird
const LOOKUP_CODE_LENGTH = 4;
const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const HeaderRow = React.forwardRef(({ heading, isTouchMode }, ref) => (
  <div className={isTouchMode ? 'header-row--touch primary' : 'header-row'} ref={ref}>
    {heading}
  </div>
));

const BlankRow = () => <div />;

const ResidentRow = ({ resident, isTouchMode }) => (
  <Link className={isTouchMode ? 'resident-row--touch' : 'resident-row'} to={`/call?resident=${resident.id}`}>
    {!isTouchMode && <div className="resident-lookup-code primary">{resident.directory_lookup_code}</div>}
    <div className="resident-display-name white">{resident.directory_display_name}</div>
  </Link>
);

class ResidentDirectory extends Component {
  static propTypes = {
    history: PropTypes.any.isRequired,
    residents: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.scrollRef = React.createRef();
    this.alphabetRefs = ALPHABET.reduce((curr, val) => {
      curr[val] = React.createRef();
      return curr;
    }, {});

    const initialState = {
      page: 0,
      lookupCode: [],
      list: null,
      totalPageCount: null,
      sectionHeaders: [],
      pageDownDisabled: false,
      pageUpDisabled: true,
    };

    this.state = merge(initialState, this.calculateStateFromProps(props));
  }

  componentDidMount() {
    window.addEventListener('normalizedButtonEvent', this.handleButton);

    this.resetTimer();
  }

  componentWillUnmount() {
    window.removeEventListener('normalizedButtonEvent', this.handleButton);

    clearTimeout(this.hardTimeout);
  }

  componentDidUpdate(prevProps) {
    const { residents } = this.props;

    if (prevProps.residents !== residents) {
      this.setState(this.calculateStateFromProps(this.props));
    }
  }

  resetTimer = () => {
    clearTimeout(this.hardTimeout);
    this.hardTimeout = setTimeout(() => {
      this.handleEscape();
    }, 60000);
  };

  calculateStateFromProps = ({ residents }) => {
    const isTouchMode = SK_MODEL === SK_MODELS.TOUCH;

    const sortedResidents = residents.sort((a, b) => {
      if (a.directory_sort_name === b.directory_sort_name) {
        return a.id - b.id;
      }
      return a.directory_sort_name > b.directory_sort_name ? 1 : -1;
    });

    const sectionHeaders = Array.from(
      sortedResidents.reduce((acc, resident) => acc.add(resident.directory_sort_name[0]), new Set())
    );

    const list = sectionHeaders.reduce((acc, heading, i) => {
      // touch mode has a single column
      const isLastRowInColumn = !isTouchMode && (acc.length === PAGE_SIZE / 2 - 1 || acc.length === PAGE_SIZE - 1);

      const headerRows = [
        <HeaderRow ref={this.alphabetRefs[heading]} isTouchMode={isTouchMode} key={heading} heading={heading} />,
      ];

      // don't let a header be the last row in a column
      if (isLastRowInColumn) {
        headerRows.unshift(<BlankRow key={`blank-${heading}-${i}`} />);
      }

      return acc.concat(
        headerRows,
        sortedResidents
          .filter(resident => resident.directory_sort_name[0] === heading)
          .map(resident => <ResidentRow key={resident.id} resident={resident} isTouchMode={isTouchMode} />)
      );
    }, []);

    const totalPageCount = Math.ceil(list.length / PAGE_SIZE);

    return {
      list,
      totalPageCount,
      sectionHeaders,
    };
  };

  handleButton = e => {
    if (e.detail.button === NORMALIZED_BUTTONS.DOWN) {
      this.handleNextPage();
    } else if (e.detail.button === NORMALIZED_BUTTONS.UP) {
      this.handlePreviousPage();
    } else if (e.detail.button === NORMALIZED_BUTTONS.BACK) {
      this.handleEscape();
    } else if (Number.isInteger(e.detail.button)) {
      this.handleLookupCodeInput(e.detail.button);
    }
  };

  handleEscape = () => {
    const { history } = this.props;

    history.replace('/');
  };

  handleNextPage = () => {
    this.resetTimer();
    this.setState(state => ({ page: state.page < state.totalPageCount - 1 ? state.page + 1 : state.page }));
  };

  handlePreviousPage = () => {
    this.resetTimer();
    this.setState(state => ({ page: state.page > 0 ? state.page - 1 : 0 }));
  };

  handleLookupCodeInput = number => {
    const { lookupCode } = this.state;
    const { residents, history } = this.props;

    this.resetTimer();

    const nextLookupCode = lookupCode.concat(number);

    this.setState(
      state => ({
        lookupCode: nextLookupCode.length === LOOKUP_CODE_LENGTH ? [] : nextLookupCode,
      }),
      () => {
        if (nextLookupCode.length >= LOOKUP_CODE_LENGTH) {
          console.log(`Have full lookup code ${nextLookupCode.join('')}`);
          const matchingResident = residents.find(
            resident => resident.directory_lookup_code === nextLookupCode.join('')
          );
          if (matchingResident) {
            console.log(
              `Match found for lookup code ${nextLookupCode.join('')}; calling resident ${matchingResident.id}`
            );
            history.replace(`/call?resident=${matchingResident.id}`);
          } else {
            console.log(`No match found for lookup code ${nextLookupCode.join('')}`);
            playSound('error');
          }
        } else {
          console.log(`Have partial lookup code ${nextLookupCode.join('')}`);
        }
      }
    );
  };

  handleListScroll = e => {
    this.resetTimer();

    const el = e.target;

    const pageDownDisabled = el.scrollHeight - el.scrollTop - el.clientHeight < 1;
    const pageUpDisabled = el.scrollTop === 0;

    this.setState({ pageDownDisabled, pageUpDisabled });
  };

  handleScrollToLetter = letter => {
    this.alphabetRefs[letter].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  handleTouchModePageUp = () => {
    this.resetTimer();

    this.scrollRef.current.scrollTo({
      top: this.scrollRef.current.scrollTop - this.scrollRef.current.clientHeight,
      behavior: 'smooth',
      block: 'start',
    });
  };

  handleTouchModePageDown = () => {
    this.resetTimer();

    this.scrollRef.current.scrollTo({
      top: this.scrollRef.current.scrollTop + this.scrollRef.current.clientHeight,
      behavior: 'smooth',
      block: 'start',
    });
  };

  renderFooterContent = () => {
    const { page, lookupCode, totalPageCount } = this.state;

    if (lookupCode.length) {
      const displayCode = new Array(LOOKUP_CODE_LENGTH).fill(0).map((_, i) =>
        i in lookupCode ? (
          <span
            className={`lookup--number ${lookupCode.length - 1 === i ? 'primary' : ''}`}
            key={`${lookupCode[i]}-${i}`}
          >
            {lookupCode[i]}
          </span>
        ) : (
          <span className="lookup--dash" key={`dash-${i}`}>
            -
          </span>
        )
      );
      return <div className="display-code">{displayCode}</div>;
    } else if (totalPageCount > 1) {
      if (page === 0) {
        return (
          <div className="resident-directory--footer">
            <div onClick={this.handleNextPage}>
              <span className="primary">▼</span>
            </div>
            <div> for more</div>
          </div>
        );
      } else if (page === totalPageCount - 1) {
        return (
          <div className="resident-directory--footer">
            <div onClick={this.handlePreviousPage}>
              <span className="primary">▲</span>
            </div>
            <div> for more</div>
          </div>
        );
      } else if (totalPageCount > 1) {
        return (
          <div className="resident-directory--footer">
            <div onClick={this.handlePreviousPage}>
              <span className="primary">▲</span>
            </div>{' '}
            <div>or </div>
            <div onClick={this.handleNextPage}>
              <span className="primary">▼</span>
            </div>
            <div> for more</div>
          </div>
        );
      }
    }
  };

  renderAlphabetList = () => {
    const { sectionHeaders } = this.state;

    return (
      <div>
        {ALPHABET.map(letter => {
          return (
            <button
              key={letter}
              type="button"
              onClick={this.handleScrollToLetter.bind(this, letter)}
              disabled={!sectionHeaders.includes(letter)}
              className="letter--button"
            >
              {letter}
            </button>
          );
        })}
      </div>
    );
  };

  renderTwoColumnView = () => {
    const { page, list } = this.state;

    const pageStart = page * PAGE_SIZE;
    const pageHalfway = page * PAGE_SIZE + PAGE_SIZE / 2;
    const pageEnd = page * PAGE_SIZE + PAGE_SIZE;

    const firstColumnRows = list.slice(pageStart, pageHalfway);
    const secondColumnRows = list.slice(pageHalfway, pageEnd);

    return (
      <div className="directory-columns__container">
        <div className="directory-column">{firstColumnRows}</div>
        <div className="spacer" />
        <div className="directory-column">{secondColumnRows}</div>
      </div>
    );
  };

  renderSingleColumnView = () => {
    const { pageDownDisabled, pageUpDisabled } = this.state;

    return (
      <div className="directory-columns__single">
        <button
          className="page-buttons--touch"
          type="button"
          disabled={pageUpDisabled}
          onClick={this.handleTouchModePageUp}
        >
          <span className="primary">▲</span> <div>page up</div>
        </button>
        <div ref={this.scrollRef} onScroll={this.handleListScroll} className="single-column-view">
          {this.state.list}
        </div>
        <button
          className="page-buttons--touch button-touch-scroll-down"
          type="button"
          disabled={pageDownDisabled}
          onClick={this.handleTouchModePageDown}
        >
          <span className="primary">▼</span> <div>page down</div>
        </button>
      </div>
    );
  };

  render() {
    const isTouch = SK_MODEL === SK_MODELS.TOUCH;

    return (
      <div className="wrapper">
        <h1 className="page-title">{STRINGS.CALL_RESIDENT_TITLE}</h1>
        <div className={`flex-row ${isTouch && 'touch-mode-portrait'}`}>
          {isTouch && <div className="alphabetList">{this.renderAlphabetList()}</div>}
          {isTouch ? this.renderSingleColumnView() : this.renderTwoColumnView()}
        </div>
        {!isTouch && this.renderFooterContent()}
        <div className="footer-actions">
          <CancelButton onClick={this.handleEscape} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  residents: state.property.residents.filter(resident => resident.enable_directory_listing),
});

export default connect(mapStateToProps)(ResidentDirectory);
