import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { pushNotification } from '../../redux/actions/app';
import { enqueueAccessRequest, openGate } from '../../redux/actions/property';
import { selectValidUserCodes } from '../../redux/selectors/property';
import CancelButton from '../../components/CancelButton';
import { STRINGS, SK_MODEL, SK_MODELS, NORMALIZED_BUTTONS } from '../../common/normalized-smartknox-models';

const ACCESS_CODE_LENGTH = 5;

class EnterCode extends Component {
  state = {
    accessCode: [],
  };

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.location.search);
    const initialCode = params.get('initialCode');
    const accessCode = [];

    if (initialCode) {
      console.log(`Have partial access code ${initialCode} from main screen`);
      accessCode.push(initialCode);
    }

    this.state = { accessCode };
  }

  static propTypes = {
    history: PropTypes.any.isRequired,
  };

  componentDidMount() {
    window.addEventListener('normalizedButtonEvent', this.handleButton);

    this.resetTimer();
  }

  componentWillUnmount() {
    window.removeEventListener('normalizedButtonEvent', this.handleButton);

    clearTimeout(this.hardTimeout);
  }

  resetTimer = () => {
    const { history } = this.props;

    clearTimeout(this.hardTimeout);
    this.hardTimeout = setTimeout(() => {
      history.replace('/');
    }, 60000);
  };

  handleButton = e => {
    const { history } = this.props;

    if (e.detail.button === NORMALIZED_BUTTONS.BACK) {
      history.replace('/');
    } else if (Number.isInteger(e.detail.button)) {
      this.handleAccessCodeInput(Number(e.detail.button));
    }
  };

  handleAccessCodeInput = number => {
    const { accessCode } = this.state;
    const { history, dispatch, validAccessCodes } = this.props;

    this.resetTimer();

    const nextAccessCode = accessCode.concat(number);

    this.setState(
      state => ({
        accessCode: nextAccessCode.length === ACCESS_CODE_LENGTH ? [] : nextAccessCode,
      }),
      () => {
        if (nextAccessCode.length >= ACCESS_CODE_LENGTH) {
          const nextAccessCodeString = nextAccessCode.join('');
          console.log(`Have full access code ${nextAccessCodeString}`);
          const accessCodeValid = validAccessCodes.some(code => code === nextAccessCodeString);

          if (accessCodeValid) {
            dispatch(openGate(nextAccessCodeString));
            dispatch(enqueueAccessRequest(nextAccessCodeString, true));
          } else {
            dispatch(pushNotification('ERROR', "Sorry, that code didn't work", null, 'sorryCode'));
            dispatch(enqueueAccessRequest(nextAccessCodeString, false));
          }

          history.replace('/');
        } else {
          console.log(`Have partial access code ${nextAccessCode.join('')}`);
        }
      }
    );
  };

  handleVirtualKeypadInput = number => {
    this.handleAccessCodeInput(number);
  };

  renderKeypad = () => {
    return (
      <div>
        <div>
          <button className="keypadNumber" type="button" onClick={() => this.handleAccessCodeInput(1)}>
            1
          </button>
          <button className="keypadNumber" type="button" onClick={() => this.handleAccessCodeInput(2)}>
            2
          </button>
          <button className="keypadNumber" type="button" onClick={() => this.handleAccessCodeInput(3)}>
            3
          </button>
        </div>
        <div>
          <button className="keypadNumber" type="button" onClick={() => this.handleAccessCodeInput(4)}>
            4
          </button>
          <button className="keypadNumber" type="button" onClick={() => this.handleAccessCodeInput(5)}>
            5
          </button>
          <button className="keypadNumber" type="button" onClick={() => this.handleAccessCodeInput(6)}>
            6
          </button>
        </div>
        <div>
          <button className="keypadNumber" type="button" onClick={() => this.handleAccessCodeInput(7)}>
            7
          </button>
          <button className="keypadNumber" type="button" onClick={() => this.handleAccessCodeInput(8)}>
            8
          </button>
          <button className="keypadNumber" type="button" onClick={() => this.handleAccessCodeInput(9)}>
            9
          </button>
        </div>
        <div className="center">
          <button className="keypadNumber" type="button" onClick={() => this.handleAccessCodeInput(0)}>
            0
          </button>
        </div>
      </div>
    );
  };

  render() {
    const { accessCode } = this.state;
    const { history } = this.props;

    const displayCode = new Array(ACCESS_CODE_LENGTH).fill(0).map((_, i) =>
      i in accessCode ? (
        <span className="primary" key={`${accessCode[i]}-${i}`}>
          *
        </span>
      ) : (
        <span className="muted" key={`dash-${i}`}>
          -
        </span>
      )
    );

    return (
      <div className="wrapper">
        <h1 className="page-title">{STRINGS.CODE_INPUT_TITLE}</h1>
        <div className="centered-box">
          <div className="inputCode">{displayCode}</div>
          {SK_MODEL === SK_MODELS.TOUCH && this.renderKeypad()}
        </div>
        <div className="footer-actions">
          <CancelButton onClick={() => history.replace('/')} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  validAccessCodes: selectValidUserCodes(state),
});

export default connect(mapStateToProps)(EnterCode);
