import { NORMALIZED_BUTTONS, NormalizedButtonEvent } from '../normalized-smartknox-models';

const getNormalizedButtonEvent = e => {
  switch (e.key) {
    case ' ':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.SPACE);
    case 'PageUp':
    case 'ArrowUp':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.UP);
    case 'Enter':
    case 'ArrowRight':
    case '#':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.GO);
    case 'PageDown':
    case 'ArrowDown':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.DOWN);
    case 'ArrowLeft':
    case 'Escape':
    case '*':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.BACK);
    case '0':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.KEYPAD_0);
    case '1':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.KEYPAD_1);
    case '2':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.KEYPAD_2);
    case '3':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.KEYPAD_3);
    case '4':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.KEYPAD_4);
    case '5':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.KEYPAD_5);
    case '6':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.KEYPAD_6);
    case '7':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.KEYPAD_7);
    case '8':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.KEYPAD_8);
    case '9':
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.KEYPAD_9);
    default:
      return null;
  }
};

window.addEventListener('keydown', e => {
  console.log(`Keyboard key ${e.key} pressed`);
  const event = getNormalizedButtonEvent(e);
  if (event && !e.metaKey && !e.ctrlKey && !e.altKey) {
    console.log(`Dispatching NormalizedButtonEvent ${event.detail.button}`);
    e.preventDefault();
    window.dispatchEvent(event);
  }
});
