import React from 'react';

const Notification = ({ status, title, body }) => {
  const styles = {
    container: {
      backgroundColor: status === 'ERROR' ? 'black' : 'green',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 10,
    },
    h1: {
      color: 'white',
    },
    h2: {
      color: 'white',
    },
  };
  return (
    <div style={styles.container}>
      <h1 style={styles.h1}>{title}</h1>
      {body && <h2 style={styles.h2}>{body}</h2>}
    </div>
  );
};

export default Notification;
