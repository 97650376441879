import Pusher from 'pusher-js';

import client from '../client';
import config from '../config';

const pusher = new Pusher(config.pusher.key, {
  cluster: config.pusher.cluster,
  encrypted: true,
  disableStats: true,
  authEndpoint: '',
  authorizer: (channel, options) => ({
    authorize: async (socket_id, callback) => {
      try {
        const response = await client.post('telephone-entry/pusher/auth', { channel_name: channel.name, socket_id });

        if (response.ok) {
          const json = await response.json();
          callback(false, json);
        } else {
          throw new Error(`Received HTTP response ${response.status} from Pusher authorize endpoint`);
        }
      } catch (e) {
        console.error(e);
        callback(true);
      }
    },
  }),
});

Pusher.logToConsole = true;

export default pusher;
