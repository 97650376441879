import merge from './utils/merge';

const process_env = JSON.parse(process.env.REACT_APP_ENV_VARS || null) || process.env;

const {
  REACT_APP_RAWR_SOCKET,
  REACT_APP_API_URL,
  REACT_APP_TWILIO_URL,
  REACT_APP_PUSHER_KEY,
  REACT_APP_PUSHER_CLUSTER,
  REACT_APP_TELNYX_API_KEY,
  REACT_APP_ENV,
} = process_env;

const defaults = {
  env: REACT_APP_ENV,
  decrementCallRateLimitTimer: 3600000, // 3600000ms = 1 hour
  rawrSocket: REACT_APP_RAWR_SOCKET,
  apiUrl: REACT_APP_API_URL,
  twilioUrl: REACT_APP_TWILIO_URL,
  telnyxAPIKey: REACT_APP_TELNYX_API_KEY,
  pusher: {
    key: REACT_APP_PUSHER_KEY,
    cluster: REACT_APP_PUSHER_CLUSTER,
  },
};

const environments = {
  development: {
    decrementCallRateLimitTimer: 10000,
  },
  qa: {},
  staging: {},
  production: {},
};

export default merge(defaults, environments[REACT_APP_ENV]);
