import { playSound } from '../../common/lib/sounds';
import client from '../../common/client';
export const pushNotification = (status, title, body, sound) => dispatch => {
  if (status === 'ERROR' && !sound) {
    playSound('sorryError');
  } else if (sound) {
    playSound(sound);
  }

  dispatch({ type: 'PUSH_NOTIFICATION', status, title, body });

  setTimeout(() => {
    dispatch({ type: 'POP_NOTIFICATION' });
  }, 5000);
};

export const handleDeviceOffline = () => ({
  type: 'DEVICE_OFFLINE',
});

export const handleDeviceOnline = () => ({
  type: 'DEVICE_ONLINE',
});

export const incrementRecentCall = resident => ({
  type: 'INCREMENT_RECENT_CALL',
  resident,
});

export const decrementRecentCall = resident => ({
  type: 'DECREMENT_RECENT_CALL',
  resident,
});

export const getFeatureFlags = () => ({
  type: 'GET_FEATURE_FLAGS',
  promise: client.get('feature-flags'),
});
