export const selectValidUserCodes = state => {
  const residentCodes = [];
  const residentCredentials = [];
  const staffCodes = [];
  const staffCredentials = [];
  const propertyGuestCodes = [];
  const propertyGuestCredentials = [];
  const guestCodes = [];
  const unitGuestCodes = [];

  if (state.property.residents) {
    for (const resident of state.property.residents) {
      if (resident.property_access_code) {
        residentCodes.push(resident.property_access_code.code);
      }

      residentCredentials.push(...resident.property_credentials.map(c => c.code));
    }
  }

  if (state.property.staff) {
    for (const staff of state.property.staff) {
      if (staff.property_access_code) {
        staffCodes.push(staff.property_access_code.code);
      }

      staffCredentials.push(...staff.property_credentials.map(c => c.code));
    }
  }

  if (state.property.propertyGuests) {
    for (const guest of state.property.propertyGuests) {
      if (guest.property_access_code) {
        propertyGuestCodes.push(guest.property_access_code.code);
      }

      propertyGuestCredentials.push(...guest.property_credentials.map(c => c.code));
    }
  }

  if (state.property.unitGuests) {
    for (const unitGuest of state.property.unitGuests) {
      if (unitGuest.propertyAccessCode && unitGuest.propertyAccessCode.active) {
        unitGuestCodes.push(unitGuest.propertyAccessCode.code);
      }
    }
  }

  return [
    ...residentCodes,
    ...residentCredentials,
    ...staffCodes,
    ...staffCredentials,
    ...propertyGuestCodes,
    ...propertyGuestCredentials,
    ...guestCodes,
    ...unitGuestCodes,
  ];
};
