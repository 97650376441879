import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Loading from './components/Loading';
import Notification from './components/Notification';
import Home from './views/Home';
import EnterCode from './views/EnterCode';
import ResidentDirectory from './views/ResidentDirectory';
import ScanQR from './views/ScanQR';
import Call from './views/Call';
import {
  getPropertyData,
  handleWiegandNotification,
  handleCheckin,
  sendNewAccessRequestsIfOnline,
} from './redux/actions/property';
import { handleDeviceOffline, handleDeviceOnline, getFeatureFlags } from './redux/actions/app';
import pusher from './common/lib/pusher';
import rawr from './common/lib/rawr';

const PROPERTY_REFRESH_INTERVAL = 60000; // 1 minute
const ERROR_REFRESH_INTERVAL = 5000; // 5 seconds
const CHECKIN_INTERVAL = 30000; // 30 seconds
const SEND_ACCESS_REQUESTS_INTERVAL = 30000; // 30 seconds

class App extends Component {
  componentDidMount() {
    const { dispatch, property } = this.props;

    dispatch(getPropertyData());
    dispatch(getFeatureFlags());

    this.propertyRefreshInterval = setInterval(() => dispatch(getPropertyData()), PROPERTY_REFRESH_INTERVAL);
    this.checkinInterval = setInterval(() => dispatch(handleCheckin()), CHECKIN_INTERVAL);
    this.sendAccessRequestsInterval = setInterval(
      () => dispatch(sendNewAccessRequestsIfOnline()),
      SEND_ACCESS_REQUESTS_INTERVAL
    );
    window.addEventListener('online', this.handleNetworkChange);
    window.addEventListener('offline', this.handleNetworkChange);

    this.bindRawr();

    if (property) {
      this.bindPusher();
    }
  }

  componentDidUpdate(prevProps) {
    const { property, propertyDataError, dispatch } = this.props;

    if (property && (!prevProps.property || prevProps.property.id !== property.id)) {
      this.bindPusher();
    }

    if (!prevProps.propertyDataError && propertyDataError) {
      clearInterval(this.propertyRefreshInterval);
      this.propertyRefreshInterval = setInterval(() => dispatch(getPropertyData()), ERROR_REFRESH_INTERVAL);
    }

    if (prevProps.propertyDataError && !propertyDataError) {
      clearInterval(this.propertyRefreshInterval);
      this.propertyRefreshInterval = setInterval(() => dispatch(getPropertyData()), PROPERTY_REFRESH_INTERVAL);
    }
  }

  componentWillUnmount() {
    clearInterval(this.propertyRefreshInterval);
    clearInterval(this.checkinInterval);
    clearInterval(this.sendAccessRequestsInterval);

    window.removeEventListener('online', this.handleNetworkChange);
    window.removeEventListener('offline', this.handleNetworkChange);
  }

  handleNetworkChange = e => {
    const { dispatch } = this.props;

    if (navigator.onLine) {
      dispatch(handleDeviceOnline());
    } else {
      dispatch(handleDeviceOffline());
    }
  };

  bindPusher = () => {
    const { property, dispatch } = this.props;

    pusher.unsubscribe(`private-telephone-entry-property-${property.id}`);
    const channel = pusher.subscribe(`private-telephone-entry-property-${property.id}`);

    channel.bind('pusher:subscription_error', status => {
      setTimeout(() => {
        channel.subscribe();
      }, ERROR_REFRESH_INTERVAL);
    });

    channel.bind('property-updated', msg => dispatch(getPropertyData()));
  };

  bindRawr = () => {
    const { dispatch } = this.props;

    // A user code was received from the hardware (e.g. prox card tapped to reader)
    rawr.notifications.onfacility((facilityId, id) => dispatch(handleWiegandNotification(id)));

    rawr.notifications.onrefresh((facilityId, id) => {
      console.log('rawr : onrefresh', facilityId, id);
      // @TODO Update hardware firmware to send facility event regardless of DMP mode so we can show correct modal
      window.location.reload();
    });
  };

  render() {
    const { propertyDataLoaded, notifications } = this.props;

    if (!propertyDataLoaded) {
      return <Loading />;
    }

    return (
      <div className="background">
        {notifications[0] && (
          <Notification status={notifications[0].status} title={notifications[0].title} body={notifications[0].body} />
        )}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/call" component={Call} />
          <Route exact path="/enter-code" component={EnterCode} />
          <Route exact path="/resident-directory" component={ResidentDirectory} />
          <Route exact path="/scan-qr" component={ScanQR} />
        </Switch>
      </div>
    );
  }
}

export default connect(state => ({
  propertyDataError: state.property.error,
  propertyDataLoaded: state.property.loaded,
  property: state.property.meta,
  notifications: state.app.notifications,
}))(App);
