import client from '../../common/client';
import rawr from '../../common/lib/rawr';
import { pushNotification } from './app';
import { selectValidUserCodes } from '../selectors/property';

export const getPropertyData = () => {
  return {
    type: 'GET_PROPERTY_DATA',
    promise: client.get('telephone-entry/property'),
  };
};

export const sendNewAccessRequestsIfOnline = () => (dispatch, getState) => {
  const state = getState();
  if (
    state.app.isOnline &&
    !state.property.areAccessRequestsSending &&
    state.property.accessRequests &&
    state.property.accessRequests.length > 0
  ) {
    dispatch({
      type: 'SEND_ACCESS_REQUESTS',
      promise: client.post('telephone-entry/access-requests', { requests: state.property.accessRequests }),
    });
  }
};

export const enqueueAccessRequest = (user_code, access_granted) => (dispatch, getState) => {
  const state = getState();
  if (state.property.loaded && !state.property.meta.clubhouse_panel_id) {
    // force user_code to string
    user_code = String(user_code);
    dispatch({
      type: 'ENQUEUE_ACCESS_REQUEST',
      accessRequest: {
        user_code,
        access_granted,
        created_at: new Date().toISOString(),
      },
    });
    dispatch(sendNewAccessRequestsIfOnline());
  }
};

export const openGate = code => dispatch => {
  if (!code) {
    console.log('rawr : openGate() called without a code');
    dispatch(pushNotification('ERROR', 'User who opened gate does not have access code'));
  } else if (rawr) {
    dispatch(pushNotification('SUCCESS', 'Access Granted!', 'You can now enter', 'gateOpen'));
    rawr.methods.wiegand(0, parseInt(code)).then(result => {
      console.log('rawr : sent wiegand', result);
    });
    setTimeout(() => {
      rawr.methods.enable_relay1().then(result => {
        console.log('rawr : local gate relay 1 triggered', result);
      });
    }, 600);
  }
};

export const handleWiegandNotification = code => (dispatch, getState) => {
  console.log('rawr : onfacility', code);

  const validAccessCodes = selectValidUserCodes(getState());

  const accessCodeValid = validAccessCodes.some(c => parseInt(c, 10) === parseInt(code, 10));

  if (accessCodeValid) {
    dispatch(openGate(`${code}`));
    dispatch(enqueueAccessRequest(code, true));
  } else {
    dispatch(pushNotification('ERROR', "Sorry, that code didn't work"));
    dispatch(enqueueAccessRequest(code, false));
  }
};

export const handleCheckin = () => {
  return {
    type: 'SERVER_CHECKIN',
    promise: client.post('telephone-entry/checkin'),
  };
};

export const persistLog = (message, ...meta) => {
  console.log(message, ...meta);
  return {
    type: 'PERSIST_LOG',
    promise: client.post('telephone-entry/logs', { message, meta, timestamp: new Date() }),
  };
};
