import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { decode } from '../../common/lib/qr';
import QRCodePhone from '../../common/icons/qr-code-phone.svg';
import CancelButton from '../../components/CancelButton';
import { pushNotification } from '../../redux/actions/app';
import { enqueueAccessRequest, openGate } from '../../redux/actions/property';
import { playSound } from '../../common/lib/sounds';
import { selectValidUserCodes } from '../../redux/selectors/property';
import { STRINGS, NORMALIZED_BUTTONS } from '../../common/normalized-smartknox-models';

class ScanQR extends Component {
  static propTypes = {
    history: PropTypes.any.isRequired,
  };

  constructor(props) {
    super(props);

    this.video = React.createRef();

    this.state = {
      videoLoaded: false,
    };
  }

  componentDidMount() {
    const { validAccessCodes, dispatch, history } = this.props;

    window.addEventListener('normalizedButtonEvent', this.handleButton);

    this.introSound = playSound('qr');

    this.hardTimeout = setTimeout(() => {
      history.replace('/');
    }, 60000);

    const hdConstraints = {
      video: { width: { exact: 240 } },
      audio: false,
    };

    navigator.mediaDevices.getUserMedia(hdConstraints).then(stream => {
      if (this.video.current) {
        this.video.current.srcObject = stream;
        this.video.current.play();

        this.video.current.addEventListener('loadeddata', e => {
          if (this.video.current) {
            this.setState({ videoLoaded: true });
            const cameraCanvas = document.createElement('canvas');
            cameraCanvas.height = this.video.current.videoHeight;
            cameraCanvas.width = this.video.current.videoWidth;
            const ctx = cameraCanvas.getContext('2d');

            const onframe = async () => {
              if (this.video.current && this.video.current.videoWidth > 0) {
                ctx.drawImage(this.video.current, 0, 0, this.video.current.videoWidth, this.video.current.videoHeight);

                try {
                  const bc = await decode(ctx);
                  if (bc) {
                    try {
                      console.log(`QR : Have full code ${bc.rawValue}`);

                      const accessCodeValid = validAccessCodes.some(code => code === bc.rawValue);

                      if (accessCodeValid) {
                        dispatch(openGate(bc.rawValue));
                        dispatch(enqueueAccessRequest(bc.rawValue, true));
                      } else {
                        dispatch(pushNotification('ERROR', "Sorry, that code didn't work", null, 'sorryCode'));
                        dispatch(enqueueAccessRequest(bc.rawValue, false));
                      }

                      history.replace('/');
                    } catch (exp) {
                      console.log('QR : Invalid code detected');
                    }
                  }
                } catch (err) {
                  console.log('QR : Error decoding QR', err);
                }

                this.scanTimer = setTimeout(() => {
                  requestAnimationFrame(onframe);
                }, 100);
              }
            };

            requestAnimationFrame(onframe);
          }
        });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('normalizedButtonEvent', this.handleButton);

    if (this.introSound) {
      try {
        this.introSound.stop();
      } catch (e) {
        console.log('ERROR: Sound could not be stopped', e);
      }
    }

    if (this.video.current && this.video.current.srcObject) {
      this.video.current.srcObject.getTracks()[0].stop();
    }
    clearTimeout(this.scanTimer);
    clearTimeout(this.hardTimeout);
  }

  handleButton = e => {
    const { history } = this.props;

    if (e.detail.button === NORMALIZED_BUTTONS.BACK) {
      history.replace('/');
    }
  };

  render() {
    const { history } = this.props;
    const { videoLoaded } = this.state;

    return (
      <div className="wrapper">
        <h1 className="page-title">{STRINGS.SCAN_QR_TITLE}</h1>
        <div className="row">
          <div className="centered-box">
            <img className="qrCode" src={QRCodePhone} alt="QR Code" />
            <p>{STRINGS.SCAN_QR_INSTRUCTIONS}</p>
          </div>
          <div className="spacer" />
          <div className="centered-box">
            <video style={videoLoaded ? {} : { display: 'none' }} ref={this.video} muted />
            {!videoLoaded && 'Loading...'}
          </div>
        </div>
        <div className="footer-actions">
          <CancelButton onClick={() => history.replace('/')} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ validAccessCodes: selectValidUserCodes(state) });

export default connect(mapStateToProps)(ScanQR);
