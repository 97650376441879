import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Device } from 'twilio-client';
import store from 'store2';
import { initialize } from '@telnyx/video';
import CancelButton from '../../components/CancelButton';
import PhoneCallInactive from '../../common/icons/phone-call.svg';
import PhoneCallActive from '../../common/icons/phone-call-yellow.svg';
import { pushNotification, incrementRecentCall, decrementRecentCall } from '../../redux/actions/app';
import { enqueueAccessRequest, openGate, persistLog } from '../../redux/actions/property';
import client from '../../common/client';
import pusher from '../../common/lib/pusher';
import { startRinging, stopRinging, playSound } from '../../common/lib/sounds';
import config from '../../common/config';
import { NORMALIZED_BUTTONS } from '../../common/normalized-smartknox-models';
import { TelnyxRTC } from '@telnyx/webrtc';

class Call extends Component {
  static propTypes = {
    history: PropTypes.any.isRequired,
  };

  constructor(props) {
    super(props);

    this.localVideo = React.createRef();
    this.remoteVideo = React.createRef();
    this.remoteAudio = React.createRef();

    this.state = {
      telnyxConnectionStatus: 'idle',
      isConnected: false,
      call: null,
      telnyxClient: null,
      peer: null,
      candidates: [],
      localStream: null,
      remoteStream: null,
    };

    this.twilioDevice = null;
    this.room = null;
    this.callEnded = false;
  }

  async componentDidMount() {
    const { resident, dispatch, recentCalls, property } = this.props;

    window.addEventListener('normalizedButtonEvent', this.handleButton);

    this.hardTimeout = setTimeout(() => {
      this.handleEscape();
    }, 300000);

    let channel = pusher.channel(`private-telephone-entry-property-${property.id}`);

    if (!channel) {
      channel = pusher.subscribe(`private-telephone-entry-property-${property.id}`);
    }
    channel.bind('client-deny', this.handleClientDeny);
    channel.bind('client-candidate', this.handleClientCandidate);
    channel.bind('client-sdp', this.handleClientSDP);
    channel.unbind('sip-message');
    channel.bind('sip-message', this.handlePusherMessage);

    if (recentCalls > 10) {
      dispatch(pushNotification('ERROR', "You're doing that too much", null, 'throttle'));
      this.handleEscape();
    } else {
      this.createCall(resident);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { localStream, remoteStream } = this.state;

    if (localStream) {
      if (prevState.localStream !== localStream && this.localVideo.current) {
        this.localVideo.current.srcObject = localStream;
        this.localVideo.current.play();
      }
    } else if (prevState.localStream) {
      for (let track of prevState.localStream.getTracks()) {
        track.stop();
      }
    }

    if (remoteStream) {
      if (prevState.remoteStream !== remoteStream && this.remoteVideo.current) {
        this.remoteAudio.current.srcObject = remoteStream;
        this.remoteAudio.current.play();
      }
    } else if (prevState.remoteStream) {
      for (let track of prevState.remoteStream.getTracks()) {
        track.stop();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('normalizedButtonEvent', this.handleButton);

    clearTimeout(this.hardTimeout);
  }

  createCall = async resident => {
    const { property, device, dispatch } = this.props;
    if (resident) {
      dispatch(
        persistLog(
          `RTC : Initiating call with resident ${resident.id} (user_enabled_video_calls=${resident.user_enabled_video_calls})`
        )
      );
    } else {
      dispatch(persistLog('RTC : Initiating call with property management'));
    }

    startRinging();

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: { exact: 240 } },
        audio: true,
      });

      dispatch(persistLog('RTC : Generated stream', stream));

      this.setState({ localStream: stream });

      let track = stream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(track);
      const CAMERA_INITIALIZATION_DELAY = 2000;

      // Setting a 2 second timeout before calling grabFrame gives the camera
      // time to initialize.
      // See: https://github.com/synapsestudios/littlebird/issues/4583
      return setTimeout(() => {
        imageCapture
          .grabFrame()
          .then(function(imageBitmap) {
            let imageData = null;
            dispatch(persistLog('RTC : Grabbed frame'));
            try {
              const canvas = document.createElement('canvas');
              canvas.width = imageBitmap.width;
              canvas.height = imageBitmap.height;
              canvas.getContext('2d').drawImage(imageBitmap, 0, 0);
              imageData = canvas.toDataURL();
            } catch (e) {
              dispatch(persistLog('RTC : Error rendering canvas:', e));
            }
            imageBitmap.close();
            return imageData;
          })
          .then(imageData =>
            client.post('telephone-entry/calls', {
              resident_id: resident ? resident.id : null,
              image_data: imageData,
              telnyx_enabled: this.props.featureFlags.telnyx_enabled,
            })
          )
          .then(response => {
            return response.json();
          })
          .then(call => {
            this.setState({
              call: call,
            });
            let peer = null;

            dispatch(persistLog('RTC : Created new call', call));

            if (!this.props.featureFlags.telnyx_enabled) {
              if (resident && resident.user_enabled_video_calls && device.enable_video_calls) {
                peer = new RTCPeerConnection({ iceServers: call.token.iceServers });
                this.setState({
                  peer,
                });

                setTimeout(() => {
                  const { call } = this.state;
                  if (call && peer && !['connected', 'completed', 'closed'].includes(peer.iceConnectionState)) {
                    dispatch(persistLog('RTC : Falling back to SIP call...'));

                    this.fallbackToSIP(resident, call);
                  }
                }, 30000);

                peer.onaddstream = e => {
                  playSound('success');
                  dispatch(persistLog('stream added'));
                  this.setState({ remoteStream: e.stream, isConnected: true });
                };

                peer.oniceconnectionstatechange = e => {
                  dispatch(persistLog('ice connection state changed', peer.iceConnectionState));
                  if (peer.iceConnectionState === 'failed' || peer.iceConnectionState === 'closed') {
                    this.callCompleted();
                    dispatch(pushNotification('ERROR', 'Call disconnected', 'Please try again later', 'sorryNoAccess'));
                  }
                };

                peer.onicecandidate = e => {
                  dispatch(persistLog('sdp candidate generated: ', e));
                  if (e.candidate) {
                    const channel = pusher.channel(`private-telephone-entry-property-${property.id}`);

                    channel.trigger('client-candidate', {
                      candidate: e.candidate,
                      call_id: call.id,
                      from: device.id,
                    });
                  }
                };

                peer.addStream(stream);
              } else {
                this.fallbackToSIP(resident, call);
              }
            } else {
              if (resident && resident.user_enabled_video_calls && device.enable_video_calls) {
                this.makeCall(resident, call, stream);
              } else {
                this.fallbackToSIP(resident, call);
              }
            }
            dispatch(incrementRecentCall(resident));
            setTimeout(() => {
              dispatch(decrementRecentCall(resident));
            }, config.decrementCallRateLimitTimer);

            dispatch(persistLog('setting call state'));
          });
      }, CAMERA_INITIALIZATION_DELAY);
    } catch (error) {
      console.error('RTC : Error generating stream');
      console.error(error);
      this.handleEscape();
    }
  };
  countParticipants = () => {
    let state = this.room.getState();

    let count = 0;

    state.participants.forEach(participant => {
      count++;
    });

    return count;
  };

  handleMessage = async (message, stream) => {
    switch (message) {
      case 'open_gate':
        this.handleEscape(true);
        break;
      case 'decline':
        this.handleEscape(false);
        break;
      case 'answered':
        this.setState({ isConnected: true });
        try {
          let guestAudio = stream.getAudioTracks()[0];
          let guestVideo = stream.getVideoTracks()[0];

          await this.room.addStream('caller', {
            audio: guestAudio,
            video: {
              track: guestVideo,
            },
          });
        } catch (e) {
          console.error(e);
        }
        break;
      default:
        break;
    }
  };
  makeCall = async (resident, data, stream) => {
    let clientToken = null;
    let apiKey = config.telnyxAPIKey;
    const context = '{"id": 10234, "username": "TE CALL"}';

    const url = `https://api.telnyx.com/v2/rooms/${data.room_id}/actions/generate_join_client_token`;
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    };
    await fetch(url, options)
      .then(res => res.json())
      .then(data => (clientToken = data.data.token));

    this.room = await initialize({
      roomId: data.room_id,
      clientToken,
      context,
      enableMessages: true,
      logLevel: 'DEBUG',
    });

    const checkForFallbackSip = setTimeout(() => {
      if (!this.callEnded) {
        this.room.disconnect();
        this.fallbackToSIP(resident, data);
      }
    }, 30000);

    console.log('initialized Video SDK...');

    this.room.on('connected', async state => {
      if (this.callEnded === true) {
        this.room.disconnect();
      }
    });

    this.room.on('message_received', (participantId, message) => {
      clearTimeout(checkForFallbackSip);
      stopRinging();
      this.handleMessage(message.payload, stream);
    });

    this.room.on('participant_joined', async (participantId, state) => {
      console.log('PARTICIPANT JOINED: ', participantId);
    });

    this.room.on('stream_published', async (participantId, streamKey, state) => {
      let participant = state.participants.get(participantId);
      if (participant.origin === 'local') {
        return;
      }

      await this.room.addSubscription(participantId, streamKey, {
        audio: true,
        video: true,
      });
    });

    this.room.on('subscription_started', (participantId, streamKey, state) => {
      console.log(`subscription to the: ${participantId} ${streamKey} stream started...`);

      let residentAudio = this.room.getParticipantStream(participantId, streamKey);

      let residentAudioStream = new MediaStream([residentAudio.audioTrack, residentAudio.videoTrack]);

      this.remoteVideo.current.srcObject = residentAudioStream;

      this.setState({ remoteStream: residentAudioStream });
    });

    try {
      await this.room.connect();
    } catch (e) {
      throw e;
    }
  };

  cleanupRoom = () => {
    let state = this.room.getState();
    if (state.streams) {
      state.streams.forEach(stream => {
        this.room.removeStream(stream.key);
      });
    }
  };

  callCompleted = shouldOpenGate => {
    const { call, activeSIPCall } = this.state;
    const { resident, staff, dispatch } = this.props;
    this.cleanup();
    dispatch(persistLog(`RTC : Ending call ${call ? call.id : 'ID unknown'}`));

    if (shouldOpenGate === true) {
      dispatch(persistLog('RTC : Resident allowed gate access'));

      let code;
      if (call && call.resident_id) {
        if (resident.property_access_code) {
          code = resident.property_access_code.code;
        } else {
          dispatch(persistLog(`RTC : Error looking up access code for resident_id=${call.resident_id}`));
        }
      } else {
        const managerWithCode = staff.find(staff => !!staff.property_access_code && !!staff.property_access_code.code);
        if (managerWithCode) {
          code = managerWithCode.property_access_code.code;
        } else {
          dispatch(persistLog('RTC : Error looking up access code for manager'));
        }
      }

      activeSIPCall && this.setState({ telnyxConnectionStatus: 'access_granted' }, () => activeSIPCall.hangup());

      dispatch(openGate(code));
      dispatch(enqueueAccessRequest(code, true));
    } else {
      dispatch(pushNotification('ERROR', "Sorry, you weren't granted access", null, 'sorryNoAccess'));
    }
    if (this.room) {
      this.cleanupRoom();
      this.room.disconnect();
    }
    this.callEnded = true;
  };
  disconnectCall = () => {
    const { telnyxClient, telnyxConnectionStatus } = this.state;
    const { dispatch } = this.props;

    if (telnyxConnectionStatus !== 'access_granted') {
      dispatch(pushNotification('ERROR', "Sorry, you weren't granted access", null, 'sorryNoAccess'));
    }

    telnyxClient.off('telnyx.error');
    telnyxClient.off('telnyx.notification');
    telnyxClient.off('telnyx.ready');
    telnyxClient.disconnect();

    if (this.room) {
      this.room.disconnect();
    }

    this.cleanup();

    this.setState(
      {
        telnyxConnectionStatus: 'idle',
        isConnected: false,
        call: null,
        telnyxClient: null,
        peer: null,
        candidates: [],
        localStream: null,
        remoteStream: null,
      },
      () => {
        this.handleEscape();
      }
    );

    this.twilioDevice = null;
  };

  cleanup = () => {
    const { peer } = this.state;
    const { property } = this.props;

    stopRinging();

    const channel = pusher.channel(`private-telephone-entry-property-${property.id}`);

    if (channel) {
      channel.unbind('client-candidate', this.handleClientCandidate);
      channel.unbind('client-sdp', this.handleClientSDP);
    }

    if (peer) {
      peer.oniceconnectionstatechange = undefined;
      peer.close();
    }

    this.setState({ localStream: null, remoteStream: null });

    if (this.twilioDevice) {
      this.twilioDevice.destroy();
    }
  };

  fallbackToSIP = async (resident, call) => {
    const { peer } = this.state;
    const { property, device, dispatch, twimlToken } = this.props;

    dispatch(persistLog('SIP : Initializing a new SIP call...'));

    const pusherChannelName = `private-telephone-entry-property-${property.id}`;
    const channel = pusher.channel(pusherChannelName);

    channel.trigger('client-hangup', {
      call_id: call.id,
      from: device.id,
    });

    if (!this.props.featureFlags.telnyx_enabled) {
      if (peer) {
        peer.oniceconnectionstatechange = undefined;
        peer.close();
      }

      window.AudioContext = undefined;
      window.webkitAudioContext = undefined;

      this.twilioDevice = new Device(twimlToken, {
        // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
        // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
        codecPreferences: ['opus', 'pcmu'],
        maxAverageBitrate: 12000,
        // Use fake DTMF tones client-side. Real tones are still sent to the other end of the call,
        // but the client-side DTMF tones are fake. This prevents the local mic capturing the DTMF tone
        // a second titwilime and sending the tone twice. This will be default in 2.0.
        fakeLocalDTMF: true,
      });

      this.twilioDevice.on('ready', device => {
        const params = {
          room: call.id,
          apiUrl: config.apiUrl,
          lbtoken: store.get('token'),
        };

        stopRinging();

        if (resident) {
          params.To = `+${resident.directory_phone_country_code}${resident.directory_phone}`;
        } else {
          params.To = `+1${property.phone}`;
        }

        if (this.state.call) {
          dispatch(persistLog('SIP : Calling... ', params));
          this.twilioDevice.connect(params);
        }
      });

      this.twilioDevice.on('error', error => {
        dispatch(persistLog('SIP : Twilio.Device Error: ' + error.message));
        this.callCompleted();
        dispatch(pushNotification('ERROR', 'An Error Occurred', 'Please try again'));
      });

      this.twilioDevice.on('connect', conn => {
        conn._monitor.disable();
        this.twilioDevice.audio._unbind();
        dispatch(persistLog('SIP : Connected', conn));
      });

      this.twilioDevice.on('disconnect', conn => {
        dispatch(persistLog('SIP : Call ended', conn));
        this.callCompleted();
      });
    } else {
      this.setState(
        {
          telnyxClient: new TelnyxRTC({
            login: call.sip_username,
            password: call.sip_password,
          }),
          telnyxConnectionStatus: 'initiating',
        },
        () => {
          this.state.telnyxClient
            .on('telnyx.ready', async () => {
              this.setState({
                telnyxConnectionStatus: 'ready',
              });
              console.log('===============>Telnyx is ready');
              const params = {
                intercomCallId: call && call.id,
                pusherChannelName,
                tenantId: call && call.resident_id,
                to: resident
                  ? `+${resident.directory_phone_country_code}${resident.directory_phone}`
                  : `+1${property.phone}`,
                username: `sip:${call.sip_username}@sip.telnyx.com`,
              };
              dispatch(persistLog('SIP : Connected, calling...', params));
              await client.post('telephone-entry/sip-call', params);
            })
            .on('telnyx.notification', this.handleSIPNotification)
            .on('telnyx.error', e => {
              dispatch(persistLog('SIP : Error: ', e.message));
              dispatch(pushNotification('ERROR', 'An Error Occurred', 'Please try again'));
              this.handleEscape();
            });

          this.state.telnyxClient.remoteElement = this.remoteVideo;

          this.state.telnyxClient.connect();
        }
      );
    }
  };
  handleSIPNotification = notification => {
    console.log('notification:', notification);
    if (notification.type === 'callUpdate') {
      const activeSIPCall = notification.call;
      this.setState({ activeSIPCall });
      switch (activeSIPCall.state) {
        case 'ringing':
          activeSIPCall.answer();
          break;
        case 'active':
          this.remoteAudio.current.srcObject = activeSIPCall.remoteStream;
          this.remoteAudio.current.play();
          break;
        case 'destroy':
          this.disconnectCall();
          break;
        case 'new':
        case 'trying':
        case 'hangup':
        default:
          console.log('Ignoring call state:', activeSIPCall.state);
      }
    }
  };

  handleClientDeny = msg => {
    const { dispatch } = this.props;
    const { call } = this.state;
    if (call && call.id === msg.call_id) {
      if (msg.open_gate !== true) {
        dispatch(pushNotification('ERROR', "Sorry, you weren't granted access", null, 'sorryNoAccess'));
      }
    }
    this.handleEscape(msg.open_gate === true);
  };

  handleClientCandidate = msg => {
    const { call, peer } = this.state;
    const { dispatch } = this.props;

    if (msg.candidate && call && call.id === msg.call_id) {
      if (peer) {
        peer.addIceCandidate(new RTCIceCandidate(msg.candidate));
      } else {
        dispatch(persistLog('sdp adding candidates'));
        this.setState(state => ({ candidates: state.candidates.concat(msg.candidate) }));
      }
    }
  };

  handleClientSDP = msg => {
    const { peer, call, candidates } = this.state;
    const { property, device, dispatch } = this.props;

    stopRinging();

    if (peer && call && call.id === msg.call_id) {
      dispatch(persistLog('sdp offer received: ', msg.sdp));

      const offer = new RTCSessionDescription(msg.sdp);
      peer.setRemoteDescription(offer);

      peer.createAnswer(msg.sdp).then(answer => {
        dispatch(persistLog('sdp answer generated: ', answer));

        const channel = pusher.channel(`private-telephone-entry-property-${property.id}`);
        channel.trigger('client-sdp', {
          sdp: answer,
          call_id: call.id,
          from: device.id,
        });

        candidates.forEach(candidate => {
          dispatch(persistLog('sdp adding client candidate: ', candidate));

          peer.addIceCandidate(new RTCIceCandidate(candidate));
        });

        dispatch(persistLog('sdp flushing candidates'));
        this.setState({ candidates: [] });

        peer.setLocalDescription(new RTCSessionDescription(answer));
      });
    }
  };

  handlePusherMessage = msg => {
    const { dispatch } = this.props;
    const { call } = this.state;

    dispatch(persistLog('SIP : Handling websocket message', msg, call));

    if (call && msg.data.room === call.id) {
      if (msg.data.connected || msg.connected) {
        stopRinging();
        dispatch(persistLog('SIP : Remote connected'));
        this.setState({ isConnected: true });
      } else if (msg.data.open) {
        this.callCompleted(true);
      }
    }
  };

  handleButton = e => {
    if (e.detail.button === NORMALIZED_BUTTONS.BACK) {
      this.handleEscape();
    }
  };

  handleEscape = shouldOpenGate => {
    const { history } = this.props;
    const { activeSIPCall } = this.state;
    stopRinging();

    if (activeSIPCall) {
      activeSIPCall.hangup();
    } else {
      this.callCompleted(shouldOpenGate);
    }
    this.cleanup();
    history.replace('/');
  };

  render() {
    const { resident } = this.props;
    const { isConnected } = this.state;

    return (
      <div className="wrapper">
        <h1 className="page-title">Call {resident ? resident.directory_display_name : 'a Manager'}</h1>
        <div className="row">
          <div className="centered-box">
            <video ref={this.localVideo} muted />
            <video className="hide" ref={this.remoteVideo} autoPlay />
            <audio ref={this.remoteAudio} autoplay />
            <h2 className="muted">(Smile, you're on camera!)</h2>
          </div>
          <div className="centered-box">
            {isConnected ? (
              <img className="phoneCallActive" src={PhoneCallActive} alt="Connected" />
            ) : (
              <img className="phoneCall" src={PhoneCallInactive} alt="Placing call" />
            )}
            <h2>
              {isConnected ? 'Connected to' : 'Calling'} {resident ? resident.directory_display_name : 'manager'}
              {isConnected ? '' : '...'}
            </h2>
          </div>
        </div>
        <div className="footer-actions">
          <CancelButton onClick={this.handleEscape} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const params = new URLSearchParams(ownProps.location.search);
  const residentId = params.get('resident');

  const resident = state.property.residents.find(res => res.id === residentId);

  const recentCalls = resident ? state.app.recentCalls[residentId] || 0 : state.app.recentCalls.manager;

  return {
    resident,
    recentCalls,
    property: state.property.meta,
    device: state.property.device,
    staff: state.property.staff,
    twimlToken: state.property.twimlToken,
    featureFlags: state.features.featureFlags,
  };
};

export default connect(mapStateToProps)(Call);
