import Client, { JwtPlugin } from '@synapsestudios/fetch-client';
import store from 'store2';

import config from './config';

const client = new Client({ url: config.apiUrl });

client.addPlugin(new JwtPlugin());
client.setJwtTokenGetter(() => `Bearer ${store.get('token')}`);

export default client;
