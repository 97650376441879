import React from 'react';
import LogoSVG from '../common/icons/logo_black.svg';
import LoadingIndicator from '../common/icons/loading-indicator.svg';

const Loading = () => {
  return (
    <div className="loading">
      <img src={LogoSVG} alt="Little Bird Logo" className="lb-logo-splash" />
      <h1>
        Loading... <img alt="Loading..." src={LoadingIndicator} />
      </h1>
    </div>
  );
};

export default Loading;
