import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { version } from '../../../package.json';
import PassSVG from '../../common/icons/password.svg';
import QRSVG from '../../common/icons/qr-code.svg';
import WomanSVG from '../../common/icons/woman.svg';
import PhoneCallSVG from '../../common/icons/phone-call.svg';
import LogoSVG from '../../common/icons/logo.svg';
import { STRINGS, NORMALIZED_BUTTONS } from '../../common/normalized-smartknox-models';

class Home extends Component {
  componentDidMount() {
    window.addEventListener('normalizedButtonEvent', this.handleButton);
  }

  componentWillUnmount() {
    window.removeEventListener('normalizedButtonEvent', this.handleButton);
  }

  handleButton = e => {
    const { history, device, isShowingNotifications, hasPropertyPhone } = this.props;
    if (isShowingNotifications) {
      return false;
    } else if (
      device.enable_resident_directory &&
      (e.detail.button === NORMALIZED_BUTTONS.UP || e.detail.button === NORMALIZED_BUTTONS.DOWN)
    ) {
      history.replace('/resident-directory');
    } else if (device.enable_qr_codes && e.detail.button === NORMALIZED_BUTTONS.GO) {
      history.replace('/scan-qr');
    } else if (device.enable_call_manager && hasPropertyPhone && e.detail.button === NORMALIZED_BUTTONS.BACK) {
      history.replace('/call');
    } else if (device.enable_numeric_codes && Number.isInteger(e.detail.button)) {
      history.replace(`/enter-code?initialCode=${e.detail.button}`);
    } else if (e.detail.button === NORMALIZED_BUTTONS.SPACE) {
      history.replace(`/call?resident=${'d5fb0751-db0b-471d-b4ca-01f6692116cf'}`);
    }
  };

  render() {
    const { propertyName, device, isOnline, hasPropertyPhone, propertyDataLoading } = this.props;

    return (
      <div className="wrapper">
        <h1 className="whenVertical page-title">Welcome to {propertyName}!</h1>
        <div className="row-container">
          <div className="row">
            {device.enable_numeric_codes && (
              <Link className="iconLink" to="/enter-code">
                <div className="iconWrapper">
                  <img src={PassSVG} alt="Keypad to Type in Code" />
                  <h2>{STRINGS.CODE_INPUT_LABEL}</h2>
                </div>
              </Link>
            )}
            {device.enable_qr_codes && (
              <Link className="iconLink" to="/scan-qr">
                <div className="iconWrapper">
                  <img src={QRSVG} alt="Press to Scan QR Code" />
                  <h2>{STRINGS.SCAN_QR_LABEL}</h2>
                </div>
              </Link>
            )}
          </div>
          <div className="row">
            {isOnline && device.enable_resident_directory && (
              <Link className="iconLink" to="/resident-directory">
                <div className="iconWrapper">
                  <img src={PhoneCallSVG} alt="Press for Resident Directory" />
                  <h2>{STRINGS.CALL_RESIDENT_LABEL}</h2>
                </div>
              </Link>
            )}
            {isOnline && device.enable_call_manager && hasPropertyPhone && (
              <Link className="iconLink" to="/call">
                <div className="iconWrapper">
                  <img src={WomanSVG} alt="Press to Call Manager" />
                  <h2>{STRINGS.CALL_MANAGER_LABEL}</h2>
                </div>
              </Link>
            )}
          </div>
        </div>
        <div className="footer">
          <div>
            {version} {propertyDataLoading && ' ...'}
          </div>
          <img src={LogoSVG} alt="Little Bird Logo" onClick={() => window.location.reload()} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  propertyName: state.property.meta.public_name,
  device: state.property.device,
  isOnline: state.app.isOnline,
  isShowingNotifications: state.app.notifications.length > 0,
  hasPropertyPhone: state.property.meta.phone ? true : false,
  propertyDataLoading: state.property.loading,
});

export default connect(mapStateToProps)(Home);
