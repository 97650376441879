import { combineReducers } from 'redux';

export default combineReducers({
  notifications: (state = [], action) => {
    switch (action.type) {
      case 'PUSH_NOTIFICATION':
        const { status, title, body } = action;
        return state.concat({ status, title, body });
      case 'POP_NOTIFICATION':
        let newState = state.slice(1);
        window.location.reload(); // dirty fix for memory leak
        return newState;
      default:
        return state;
    }
  },
  isOnline: (state = navigator.onLine, action) => {
    switch (action.type) {
      case 'DEVICE_ONLINE':
        return true;
      case 'DEVICE_OFFLINE':
        return false;
      default:
        return state;
    }
  },
  recentCalls: (state = {}, action) => {
    const resId = (action.resident && action.resident.id) || 'manager';
    switch (action.type) {
      case 'INCREMENT_RECENT_CALL':
        return { ...state, [resId]: state[resId] ? state[resId] + 1 : 1 };
      case 'DECREMENT_RECENT_CALL':
        return { ...state, [resId]: state[resId] && state[resId] > 0 ? state[resId] - 1 : 0 };
      default:
        return state;
    }
  },
});
