import { combineReducers } from 'redux';

export default combineReducers({
  error: (state = false, action) => {
    switch (action.type) {
      case 'GET_PROPERTY_DATA_SUCCESS':
        return false;
      case 'GET_PROPERTY_DATA_ERROR':
      case 'GET_PROPERTY_DATA_FAILURE':
        return true;
      default:
        return state;
    }
  },
  loading: (state = false, action) => {
    switch (action.type) {
      case 'GET_PROPERTY_DATA_REQUEST':
        return true;
      case 'GET_PROPERTY_DATA_SUCCESS':
      case 'GET_PROPERTY_DATA_FAILURE':
        return false;
      default:
        return state;
    }
  },
  loaded: (state = false, action) => {
    switch (action.type) {
      case 'GET_PROPERTY_DATA_SUCCESS':
        return true;
      default:
        return state;
    }
  },
  meta: (state = null, action) => {
    switch (action.type) {
      case 'GET_PROPERTY_DATA_SUCCESS':
        return action.json.property;
      default:
        return state;
    }
  },
  residents: (state = null, action) => {
    switch (action.type) {
      case 'GET_PROPERTY_DATA_SUCCESS':
        return action.json.residents;
      default:
        return state;
    }
  },
  staff: (state = null, action) => {
    switch (action.type) {
      case 'GET_PROPERTY_DATA_SUCCESS':
        return action.json.property_staff;
      default:
        return state;
    }
  },
  unitGuests: (state = null, action) => {
    switch (action.type) {
      case 'GET_PROPERTY_DATA_SUCCESS':
        return action.json.unitGuests;
      default:
        return state;
    }
  },
  propertyGuests: (state = null, action) => {
    switch (action.type) {
      case 'GET_PROPERTY_DATA_SUCCESS':
        return action.json.property_guests;
      default:
        return state;
    }
  },
  device: (state = null, action) => {
    switch (action.type) {
      case 'GET_PROPERTY_DATA_SUCCESS':
        return action.json.device;
      default:
        return state;
    }
  },
  twimlToken: (state = null, action) => {
    switch (action.type) {
      case 'GET_PROPERTY_DATA_SUCCESS':
        return action.json.twimlToken;
      default:
        return state;
    }
  },
  accessRequests: (state = [], action) => {
    switch (action.type) {
      case 'ENQUEUE_ACCESS_REQUEST':
        return [...state, action.accessRequest];
      case 'SEND_ACCESS_REQUESTS_SUCCESS':
        return state.filter(
          accessRequest =>
            !action.json.find(
              item =>
                accessRequest.user_code === item.user_code &&
                accessRequest.created_at === item.created_at &&
                accessRequest.access_granted === item.access_granted
            )
        );
      default:
        return state;
    }
  },
  areAccessRequestsSending: (state = false, action) => {
    switch (action.type) {
      case 'SEND_ACCESS_REQUESTS_REQUEST':
        return true;
      case 'SEND_ACCESS_REQUESTS_SUCCESS':
      case 'SEND_ACCESS_REQUESTS_FAILURE':
      case 'SEND_ACCESS_REQUESTS_ERROR':
        return false;
      default:
        return state;
    }
  },
});
