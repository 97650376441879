import { NORMALIZED_BUTTONS, NormalizedButtonEvent } from '../normalized-smartknox-models';

const lastGamepadButtonState = [];

const getNormalizedButtonEvent = idx => {
  switch (idx) {
    case 0:
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.UP);
    case 1:
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.GO);
    case 2:
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.DOWN);
    case 3:
      return new NormalizedButtonEvent(NORMALIZED_BUTTONS.BACK);
    default:
      return null;
  }
};

function handleButtonPress(idx) {
  console.log(`Gamepad button ${idx} pressed`);
  const event = getNormalizedButtonEvent(idx);
  if (event) {
    console.log(`Dispatching NormalizedButtonEvent ${event.detail.button}`);
    window.dispatchEvent(event);
  }
}

function pollGamepad() {
  if (navigator.getGamepads) {
    const gamepad = navigator.getGamepads()[0];
    if (gamepad && gamepad.buttons) {
      gamepad.buttons.forEach(function(btn, idx) {
        if (btn.value && lastGamepadButtonState[idx] !== btn.value) {
          handleButtonPress(idx);
        }
        lastGamepadButtonState[idx] = btn.value;
      });
    }
  }

  window.requestAnimationFrame(pollGamepad);
}

window.addEventListener('gamepadconnected', function(e) {
  console.log(`Gamepad connected: ${e.gamepad.id}`);
  const gamepad = navigator.getGamepads()[0];
  gamepad.buttons.forEach((btn, idx) => {
    if (btn.value) {
      handleButtonPress(idx);
    }
    lastGamepadButtonState[idx] = btn.value;
  });
  pollGamepad();
});
