import rawr from 'rawr';
import transport from 'rawr/transports/websocket';
import ReconnectingSocket from 'reconnecting-websocket';

import config from '../config';

let rawrPeer;

try {
  const wsClient = new ReconnectingSocket(config.rawrSocket, '', {
    minReconnectionDelay: 1,
  });

  wsClient.addEventListener('open', () => {
    console.log('WS : Opened');
  });

  wsClient.addEventListener('close', () => {
    console.log('WS : Closed');
  });

  wsClient.addEventListener('error', err => {
    console.log('WS : Error', err);
  });

  rawrPeer = rawr({ transport: transport(wsClient, true) });
} catch (e) {
  console.error('WS : Error establishing websocket connection', e);
}

export default rawrPeer;
