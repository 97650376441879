import { combineReducers } from 'redux';

export const FEATURE_FLAGS = {
  TELNYX_ENABLED: 'telnyx_enabled',
};

const featureFlagDefaults = {
  [FEATURE_FLAGS.TELNYX_ENABLED]: false,
};

export default combineReducers({
  featureFlags: (state = featureFlagDefaults, action) => {
    switch (action.type) {
      case 'GET_FEATURE_FLAGS_SUCCESS':
        return action.json;
      default:
        return state;
    }
  },
});
