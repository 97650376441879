import { soundManager } from 'soundmanager2';

import qrWav from '../sounds/qr01.wav';
import sorryCodeWav from '../sounds/sorrycode02.wav';
import sorryErrorWav from '../sounds/sorryerror03.wav';
import sorryNoAccessWav from '../sounds/sorrynoaccess02.wav';
import sorryNoAnswerWav from '../sounds/sorrynoanswer02.wav';
import throttleWav from '../sounds/throttle03.wav';
import welcomeWav from '../sounds/welcome04.wav';
import ringWav from '../sounds/ring.wav';
import errorWav from '../sounds/error.wav';
import successWav from '../sounds/success.wav';
import gateOpenWav from '../sounds/gateopen.wav';

const sounds = {};
let ring = null;

soundManager.setup({
  onready: () => {
    sounds.error = soundManager.createSound({
      url: errorWav,
      autoLoad: true,
      multiShot: false,
    });

    sounds.success = soundManager.createSound({
      url: successWav,
      autoLoad: true,
      multiShot: false,
    });

    sounds.qr = soundManager.createSound({
      url: qrWav,
      autoLoad: true,
      multiShot: false,
    });

    sounds.sorryCode = soundManager.createSound({
      url: sorryCodeWav,
      autoLoad: true,
      multiShot: false,
    });

    sounds.sorryError = soundManager.createSound({
      url: sorryErrorWav,
      autoLoad: true,
      multiShot: false,
    });

    sounds.sorryNoAccess = soundManager.createSound({
      url: sorryNoAccessWav,
      autoLoad: true,
      multiShot: false,
    });

    sounds.sorryNoAnswer = soundManager.createSound({
      url: sorryNoAnswerWav,
      autoLoad: true,
      multiShot: false,
    });

    sounds.throttle = soundManager.createSound({
      url: throttleWav,
      autoLoad: true,
      multiShot: false,
    });

    sounds.ring = soundManager.createSound({
      url: ringWav,
      autoLoad: true,
      multiShot: false,
    });

    sounds.welcome = soundManager.createSound({
      url: welcomeWav,
      autoLoad: true,
      multiShot: false,
    });

    sounds.gateOpen = soundManager.createSound({
      url: gateOpenWav,
      autoLoad: true,
      multiShot: false,
    });
  },
});

export const playSound = (sound, options = {}) => {
  soundManager.stopAll();
  if (sounds[sound]) {
    return sounds[sound].play(options);
  } else {
    console.error(`Sound ${sound} not loaded yet`);
  }
};

export const stopSound = () => {
  soundManager.stopAll();
};

export const startRinging = () => {
  stopRinging();
  ring = playSound('ring', { loops: Infinity });
};

export const stopRinging = () => {
  if (ring) {
    ring.stop();
  }
};
