import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createResponsiveStateReducer } from 'redux-responsive';

import property from './redux/reducers/property';
import app from './redux/reducers/app';
import features from './redux/reducers/features';
const rootReducer = combineReducers({
  browser: createResponsiveStateReducer(),
  property,
  app,
  features,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['app'],
};

export default persistReducer(persistConfig, rootReducer);
