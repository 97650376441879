import rawr from 'rawr';
import transport from 'rawr/transports/worker';

const rawrPeer = rawr({ transport: transport(new Worker('/scripts/worker.js')) });

let ready = false;

rawrPeer.notifications.onready(() => {
  console.log('QR : worker ready');
  ready = true;
});

export const decode = async function(context) {
  if (!ready) {
    return null;
  }
  try {
    const canvas = context.canvas;
    const width = canvas.width;
    const height = canvas.height;
    const imageData = context.getImageData(0, 0, width, height);
    const result = await rawrPeer.methods.detectUrl(width, height, imageData);
    return result;
  } catch (err) {
    console.log('QR : RPC error', err);
    return null;
  }
};
