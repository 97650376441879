import React from 'react';
import store from 'store2';

const model = store.get('model');

export const NORMALIZED_BUTTONS = {
  UP: 'UP',
  DOWN: 'DOWN',
  BACK: 'BACK',
  GO: 'GO',
  SPACE: ' ',
  KEYPAD_0: 0,
  KEYPAD_1: 1,
  KEYPAD_2: 2,
  KEYPAD_3: 3,
  KEYPAD_4: 4,
  KEYPAD_5: 5,
  KEYPAD_6: 6,
  KEYPAD_7: 7,
  KEYPAD_8: 8,
  KEYPAD_9: 9,
};

export const SK_MODELS = {
  DEFAULT: 'DEFAULT',
  GEN1: 'GEN1',
  GEN2: 'GEN2',
  GEN3: 'GEN3',
  TOUCH: 'TOUCH',
};

// Pick the current model out of the querystring or fall back to "DEFAULT"
export const SK_MODEL = Object.keys(SK_MODELS).includes(model) ? model : SK_MODELS.DEFAULT;

const BUTTON_LABELS = {
  [SK_MODELS.DEFAULT]: {
    [NORMALIZED_BUTTONS.UP]: '▲',
    [NORMALIZED_BUTTONS.DOWN]: '▼',
    [NORMALIZED_BUTTONS.BACK]: '◀ BACK',
    [NORMALIZED_BUTTONS.GO]: 'GO ▶',
  },
  [SK_MODELS.GEN1]: {
    [NORMALIZED_BUTTONS.UP]: '▲',
    [NORMALIZED_BUTTONS.DOWN]: '▼',
    [NORMALIZED_BUTTONS.BACK]: 'Esc',
    [NORMALIZED_BUTTONS.GO]: 'Enter',
  },
  [SK_MODELS.GEN2]: {
    [NORMALIZED_BUTTONS.UP]: '▲',
    [NORMALIZED_BUTTONS.DOWN]: '▼',
    [NORMALIZED_BUTTONS.BACK]: 'Back',
    [NORMALIZED_BUTTONS.GO]: 'Enter',
  },
  [SK_MODELS.GEN3]: {
    [NORMALIZED_BUTTONS.UP]: '▲',
    [NORMALIZED_BUTTONS.DOWN]: '▼',
    [NORMALIZED_BUTTONS.BACK]: '✱',
    [NORMALIZED_BUTTONS.GO]: '#',
  },
  // These won't be used, but must be defined
  [SK_MODELS.TOUCH]: {
    [NORMALIZED_BUTTONS.UP]: '',
    [NORMALIZED_BUTTONS.DOWN]: '',
    [NORMALIZED_BUTTONS.BACK]: '',
    [NORMALIZED_BUTTONS.GO]: '',
  },
}[SK_MODEL];

// UI strings used for SmartKnox models with buttons
// Not yet comprehensive - e.g. missing Home screen title and various alert text
const buttonStrings = {
  CODE_INPUT_LABEL: (
    <span>
      Enter a <span className="primary">numeric code</span>
    </span>
  ),
  CODE_INPUT_TITLE: 'Enter a numeric code',
  SCAN_QR_LABEL: (
    <span>
      <span className="primary">{BUTTON_LABELS[NORMALIZED_BUTTONS.GO]}</span> to scan a QR code
    </span>
  ),
  SCAN_QR_TITLE: 'Scan a QR code',
  SCAN_QR_INSTRUCTIONS: 'If you were texted a QR code, pull it up and center it on the camera',
  CALL_RESIDENT_LABEL: (
    <span>
      <span className="primary">{BUTTON_LABELS[NORMALIZED_BUTTONS.UP]}</span> or{' '}
      <span className="primary">{BUTTON_LABELS[NORMALIZED_BUTTONS.DOWN]}</span> to call a resident
    </span>
  ),
  CALL_RESIDENT_TITLE: 'Enter a number to call a resident',
  CALL_MANAGER_LABEL: (
    <span>
      <span className="primary">{BUTTON_LABELS[NORMALIZED_BUTTONS.BACK]}</span> to call a manager
    </span>
  ),
  CANCEL_BUTTON_LABEL: `Press ${BUTTON_LABELS[NORMALIZED_BUTTONS.BACK]} to cancel`,
};

// UI strings used for SmartKnox models without buttons
const touchStrings = {
  CODE_INPUT_LABEL: (
    <span>
      Enter a <span className="primary">numeric code</span>
    </span>
  ),
  CODE_INPUT_TITLE: 'Enter a numeric code',
  SCAN_QR_LABEL: <span>Scan a QR code</span>,
  SCAN_QR_TITLE: 'Scan a QR code',
  SCAN_QR_INSTRUCTIONS: 'If you were texted a QR code, pull it up and center it on the camera',
  CALL_RESIDENT_LABEL: <span>Call a resident</span>,
  CALL_RESIDENT_TITLE: 'Tap a resident to call',
  CALL_MANAGER_LABEL: <span>Call a manager</span>,
  CANCEL_BUTTON_LABEL: '◀ CANCEL',
};

export const STRINGS = {
  [SK_MODELS.DEFAULT]: buttonStrings,
  [SK_MODELS.GEN1]: buttonStrings,
  [SK_MODELS.GEN2]: buttonStrings,
  [SK_MODELS.GEN3]: buttonStrings,
  [SK_MODELS.TOUCH]: touchStrings,
}[SK_MODEL];

export class NormalizedButtonEvent extends CustomEvent {
  constructor(button) {
    if (!Object.values(NORMALIZED_BUTTONS).includes(button)) {
      throw new Error('Invalid button passed to NormalizedButtonEvent');
    }
    super('normalizedButtonEvent', { detail: { button } });
  }
}
