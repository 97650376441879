import React from 'react';
import { STRINGS } from '../common/normalized-smartknox-models';

const CancelButton = ({ onClick }) => {
  return (
    <button type="button" onClick={onClick}>
      {STRINGS.CANCEL_BUTTON_LABEL}
    </button>
  );
};

export default CancelButton;
