import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { BrowserRouter, Route } from 'react-router-dom';
import URLSearchParams from '@ungap/url-search-params';
import store from 'store2';

import { Provider } from 'react-redux';
import { createDevTools } from 'redux-devtools';
import { compose, createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { responsiveStoreEnhancer } from 'redux-responsive';
import LogMonitor from 'redux-devtools-log-monitor';
import DockMonitor from 'redux-devtools-dock-monitor';
import thunk from 'redux-thunk';

import * as serviceWorker from './service-worker-registration';
import apiRequestMiddleware from './redux/middleware/apiRequestMiddleware';
import consoleLogMiddleware from './redux/middleware/consoleLogMiddleware';
import reducers from './reducers';
import App from './App';
import './index.css';
import './common/lib/gamepad';
import './common/lib/keyboard';
import config from './common/config';

// Polyfill URLSearchParams so fetch-client works in IE
window.URLSearchParams = URLSearchParams;

Sentry.init({
  dsn: 'https://7603e9ad08289e9a79e56e1b4a10f51f@o4505975885135872.ingest.sentry.io/4505983648333824',
  environment: config.env,
});
var consoleError = console.error;
console.error = function(error) {
  Sentry.captureException(error);
  return consoleError(error);
};

const params = new URLSearchParams(window.location.search);

if (params.get('token')) {
  console.log('Setting API token from URL param');
  store.set('token', params.get('token'));
}

if (params.get('model')) {
  console.log('Settings model from URL param');
  store.set('model', params.get('model'));
}

if (!store.get('token')) {
  throw new Error('No LittleBird API token defined');
} else {
  console.log('Using API token from local storage');
}

if (store.get('model')) {
  console.log('Using model from local storage');
}

// Apply Redux middleware
let storeEnhancer = compose(
  responsiveStoreEnhancer,
  applyMiddleware(apiRequestMiddleware, thunk, consoleLogMiddleware)
);

// Apply Redux DevTools in development only
let DevTools = null;

const { NODE_ENV } = config;

if (NODE_ENV === 'development') {
  DevTools = createDevTools(
    <DockMonitor toggleVisibilityKey="ctrl-h" changePositionKey="ctrl-q" defaultIsVisible={false}>
      <LogMonitor theme="tomorrow" />
    </DockMonitor>
  );

  storeEnhancer = compose(storeEnhancer, DevTools.instrument());
}

// Bootstrap the React app
const reduxStore = storeEnhancer(createStore)(reducers);
const persistor = persistStore(reduxStore);

ReactDOM.render(
  <Provider store={reduxStore}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <div>
          <Route component={App} />
          <Route component={DevTools} />
        </div>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
